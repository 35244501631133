import { useState } from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'

import { fromGlobalId } from 'utils/transformations/graphql'

import {
  getAccountId,
  userIsInternal,
  userIsOwner,
} from 'store/currentUser/selectors'

import AccountMenuTrigger from './components/AccountMenuTrigger/AccountMenuTrigger'
import CommonSettings from './components/CommonSettings/CommonSettings'
import CurrentAccountSection from './components/CurrentAccountSection/CurrentAccountSection'
import OtherAccountsSection from './components/OtherAccountsSection/OtherAccountsSection'
import {
  MergeButton,
  OptionWrapper,
  OptionsContainer,
} from './retailerAccountsDropdown.ui'
import {
  RecentlyCreatedAccountType,
  useGetLiteRetailerAccountsInfo,
} from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.queries'
import MergeAccountsModal from 'features/Navbar/NavbarRevamp/components/AccountsDropdown/MergeAccountsModal/MergeAccountsModal'
import NavbarDropdown from 'features/Navbar/NavbarRevamp/components/NavbarDropdown/NavbarDropdown'

export const AccountsOptions = ({
  className,
  recentlyCreatedAccounts = [],
}: {
  className?: string
  recentlyCreatedAccounts?: RecentlyCreatedAccountType[]
}) => {
  const accountId = useSelector(getAccountId)
  const [isMergeModalOpen, setMergeModalOpen] = useState(false)
  const isAccountOwner = useSelector(userIsOwner)
  const isInternalUser = useSelector(userIsInternal)

  const { userAccounts, accounts } = useGetLiteRetailerAccountsInfo()

  const { mergeAccounts } = useFlags()
  const hasAccountsToMerge =
    userAccounts.filter(
      (account: { assortmentPlanning: boolean }) => !account.assortmentPlanning,
    )?.length >= 0

  const canMergeAccounts =
    ((isAccountOwner && mergeAccounts) || isInternalUser) && hasAccountsToMerge

  return (
    <OptionsContainer className={className}>
      <CurrentAccountSection accounts={accounts} userAccounts={userAccounts} />
      {userAccounts.length > 1 && (
        <OtherAccountsSection
          accounts={accounts}
          userAccounts={userAccounts.filter(
            (userAccount) =>
              fromGlobalId(userAccount.id)?.id !== String(accountId),
          )}
          recentlyCreatedAccounts={recentlyCreatedAccounts}
        />
      )}
      <CommonSettings />
      <OptionWrapper>
        {canMergeAccounts && (
          <MergeButton onClick={() => setMergeModalOpen(true)}>
            Merge Duplicate Accounts
          </MergeButton>
        )}
      </OptionWrapper>
      {canMergeAccounts && isMergeModalOpen && (
        <MergeAccountsModal
          isOpen={isMergeModalOpen}
          setOpen={setMergeModalOpen}
          accounts={userAccounts}
          accountId={String(accountId)}
        />
      )}
    </OptionsContainer>
  )
}

const RetailAccountsDropdown = ({
  recentlyCreatedAccounts,
}: {
  recentlyCreatedAccounts?: RecentlyCreatedAccountType[]
}) => (
  <NavbarDropdown
    rightPosition
    trigger={
      <AccountMenuTrigger recentlyCreatedAccounts={recentlyCreatedAccounts} />
    }
  >
    <AccountsOptions recentlyCreatedAccounts={recentlyCreatedAccounts} />
  </NavbarDropdown>
)

export default RetailAccountsDropdown
